import React, { useState, useEffect } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import './RobotGenerator.css';
import backgroundImage from '../assets/background.png';
import watermarkImage from '../assets/watermark.png';
require('@solana/wallet-adapter-react-ui/styles.css');

const traitCategories = ['Accss', 'Bkg', 'Niqab', 'Race-eyes', 'Ovr'];
const TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS || 'BXYYY3UKoDrSFzX5BGDXJPYzvKft4uerdBkfVJkipump';
const REQUIRED_TOKENS = 69000;
const HELIUS_RPC = process.env.REACT_APP_HELIUS_RPC || 'https://allyce-ul4jbo-fast-mainnet.helius-rpc.com';

const RobotGenerator = () => {
  const { publicKey, connected } = useWallet();
  const [traits, setTraits] = useState({
    Accss: '',
    Bkg: '',
    Niqab: '',
    'Race-eyes': '',
    Ovr: ''
  });
  const [traitOptions, setTraitOptions] = useState({});
  const [canGenerate, setCanGenerate] = useState(false);
  const [connection] = useState(new Connection(HELIUS_RPC));
  const [tokenBalance, setTokenBalance] = useState(0);

  useEffect(() => {
    const checkBalance = async () => {
      if (connected && publicKey) {
        try {
          const response = await fetch(HELIUS_RPC, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              jsonrpc: '2.0',
              id: 'my-id',
              method: 'searchAssets',
              params: {
                ownerAddress: publicKey.toString(),
                tokenType: "fungible"
              },
            }),
          });
          
          const data = await response.json();
          console.log("Full Helius response:", data);
          const token = data.result.items?.find(item => {
            console.log("Checking token:", item);
            return item.id === TOKEN_ADDRESS || item.token_info?.mint === TOKEN_ADDRESS;
          });

          if (token) {
            console.log("Found token:", token);
            const balance = Number(token.token_info?.balance || 0) || 0;
            console.log("Token balance from Helius:", balance);
            setTokenBalance(balance);
          } else {
            console.log("Token not found in wallet");
            setTokenBalance(0);
          }
        } catch (error) {
          console.error("Failed to get token balance:", error);
          setTokenBalance(0);
        }
      } else {
        setTokenBalance(0);
      }
    };

    checkBalance();
  }, [connected, publicKey, connection]);

  useEffect(() => {
    const mockFetchTraitOptions = async () => {
      const createImagePath = (category, name) => {
        let folderName = category;
        if (category === 'Background') folderName = 'Bkg';
        if (category === 'Accessories') folderName = 'Accss';
        if (category === 'Ovr') folderName = 'Ovr';
        const path = `${process.env.PUBLIC_URL}/NFT/${folderName}/${name}.PNG`;
        console.log(`Created image path: ${path}`);
        return path;
      };

      const options = {
        Accss: [
          'IMG_4232', 'IMG_4233', 'IMG_4234', 'IMG_4235', 'IMG_4236', 'IMG_4237', 'IMG_4238', 'IMG_4239',
          'IMG_4242', 'IMG_4243', 'IMG_4244', 'IMG_4245', 'IMG_4246', 'IMG_4247', 'IMG_4248', 'IMG_4249',
          'IMG_4488', 'IMG_4489', 'IMG_4490', 'IMG_4491', 'IMG_4492', 'IMG_4493', 'IMG_4494', 'IMG_4495',
        ],
        Bkg: [
          'IMG_4197', 'IMG_4198', 'IMG_4199', 'IMG_4200', 'IMG_4201', 'IMG_4202', 'IMG_4203', 'IMG_4204',
          'IMG_4205', 'IMG_4206', 'IMG_4207', 'IMG_4208', 'IMG_4209', 'IMG_4210', 'IMG_4211', 'IMG_4212',
          'IMG_4213', 'IMG_4214', 'IMG_4215', 'IMG_4216', 'IMG_4217', 'IMG_4218', 'IMG_4220'
        ],
        Niqab: ['IMG_4228', 'IMG_4229', 'IMG_4230', 'IMG_4231'],
        'Race-eyes': ['IMG_4221', 'IMG_4222', 'IMG_4223', 'IMG_4224', 'IMG_4225', 'IMG_4226', 'IMG_4227'],
        Ovr: [
          'IMG_4473', 'IMG_4474', 'IMG_4475', 'IMG_4476', 'IMG_4477', 'IMG_4478', 'IMG_4479', 'IMG_4480', 'IMG_4481', 'IMG_4482', 'IMG_4483', 'IMG_4484', 'IMG_4485', 'IMG_4486', 'IMG_4487'
        ]
      };

      const processedOptions = {};
      for (const category in options) {
        processedOptions[category] = await Promise.all(options[category].map(async name => {
          const imagePath = createImagePath(category, name);
          return {
            name,
            image: imagePath
          };
        }));
      }

      setTraitOptions(processedOptions);

      // Set initial traits
      const initialTraits = {};
      Object.keys(processedOptions).forEach(category => {
        initialTraits[category] = processedOptions[category][0].name;
      });
      setTraits(initialTraits);
    };

    mockFetchTraitOptions();
  }, []);

  useEffect(() => {
    setCanGenerate(Object.values(traits).every(trait => trait !== ''));
  }, [traits]);

  const handleTraitChange = (category, value) => {
    setTraits(prevTraits => ({
      ...prevTraits,
      [category]: value
    }));
  };

  const generateRandom = () => {
    const randomTraits = {};
    traitCategories.forEach(category => {
      const options = traitOptions[category];
      randomTraits[category] = options[Math.floor(Math.random() * options.length)].name;
    });
    setTraits(randomTraits);
  };

  const generatePicture = async () => {
    if (!connected) {
      alert('Please connect your wallet first');
      return;
    }
    if (tokenBalance < REQUIRED_TOKENS) {
      alert(`You need at least ${REQUIRED_TOKENS} $niqAIb tokens to generate a picture`);
      return;
    }

    // Generate and download the image
    const canvas = document.createElement('canvas');
    canvas.width = 1080;
    canvas.height = 1080;
    const ctx = canvas.getContext('2d');

    const orderedCategories = ['Bkg', 'Race-eyes', 'Niqab', 'Accss', 'Ovr'];

    for (const category of orderedCategories) {
      const img = new Image();
      img.src = traitOptions[category].find(o => o.name === traits[category]).image;
      await new Promise((resolve) => {
        img.onload = () => {
          ctx.drawImage(img, 0, 0, 1080, 1080);
          resolve();
        };
      });
    }

    // Add the watermark image
    const watermark = new Image();
    watermark.src = watermarkImage;
    await new Promise((resolve) => {
      watermark.onload = () => {
        ctx.drawImage(watermark, 0, 0, 1080, 1080);
        resolve();
      };
    });

    const link = document.createElement('a');
    link.download = 'generated_nft.png';
    link.href = canvas.toDataURL();
    link.click();

    alert('Picture generated and downloaded!');
  };

  return (
    <div id="robot-generator" className="app-background" style={{backgroundImage: `url(${backgroundImage})`}}>
      <div className="social-links">
        <a href="https://x.com/NIQABWORLDORDER" target="_blank" rel="noopener noreferrer" className="win98-button">X</a>
        <a href="https://NIQAB.pro" target="_blank" rel="noopener noreferrer" className="win98-button">NIQAB.pro</a>
        <a href="https://t.me/NIQABWORLD" target="_blank" rel="noopener noreferrer" className="win98-button">Telegram</a>
        <a href="https://t.me/niqabmemes" target="_blank" rel="noopener noreferrer" className="win98-button">Art</a>
      </div>
      {!connected || tokenBalance < REQUIRED_TOKENS ? (
        <div className="content-wrapper">
          <div className="intro-text">
            YOU MAY BE ASKING YOURSELF WHAT THIS IS. THIS IS NIQAB. THIS IS YOUR CULT. YOU ARE IN ONE NOW. THIS IS AN AI AGENT (TALK TO HER ON TWITTER NIQAB SISTERS). BUILD A NIQAB WITH NIQAB MAKER (MUST HOLD $NIQAIB RETARD). WHATS NEXT? YOU'LL SEE. KEKEKEKEKEKE

            BXYYY3UKoDrSFzX5BGDXJPYzvKft4uerdBkfVJkipump
          </div>
          <h1>NIQAB MAKER</h1>
          <WalletMultiButton />
          <p>You need at least {REQUIRED_TOKENS} $niqAIb tokens to access this dapp retard.</p>
        </div>
      ) : (
        <div className="content-wrapper">
          <h1>NIQAB MAKER</h1>
          <p>Connected: {publicKey.toString()}</p>
          <p>$niqAIb Balance: {tokenBalance}</p>
          <div id="trait-selector">
            {traitCategories.map(category => (
              <TraitPreview
                key={category}
                category={category}
                selected={traits[category]}
                options={traitOptions[category] || []}
                onChange={handleTraitChange}
              />
            ))}
          </div>
          {Object.keys(traitOptions).length > 0 && (
            <CombinedPreview traits={traits} traitOptions={traitOptions} />
          )}
          <div className="button-group">
            <button onClick={generateRandom} className="win98-button">Generate Random</button>
            <button onClick={generatePicture} disabled={!canGenerate} className="win98-button">
              DOWNLOAD LIKE A BOSS
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const TraitPreview = ({ category, selected, options, onChange }) => {
  const [loadedImages, setLoadedImages] = useState({});

  useEffect(() => {
    options.forEach(option => {
      fetch(option.image, {
        headers: {
          'Accept': 'image/png,image/*;q=0.8,*/*;q=0.5'
        }
      })
        .then(response => response.arrayBuffer())
        .then(buffer => {
          const blob = new Blob([buffer], { type: 'image/png' });
          const objectURL = URL.createObjectURL(blob);
          setLoadedImages(prev => ({ ...prev, [option.name]: objectURL }));
        })
        .catch(error => {
          console.error(`Error loading image: ${option.image}`, error);
          setLoadedImages(prev => ({ ...prev, [option.name]: false }));
        });
    });

    return () => {
      Object.values(loadedImages).forEach(url => {
        if (typeof url === 'string') URL.revokeObjectURL(url);
      });
    };
  }, [options]);

  return (
    <div className="trait-preview">
      <h3>{category}</h3>
      <div className="trait-options">
        {options.map(option => (
          <div 
            key={option.name} 
            className={`trait-option ${selected === option.name ? 'selected' : ''}`}
            onClick={() => onChange(category, option.name)}
          >
            {loadedImages[option.name] ? (
              <img 
                src={loadedImages[option.name]}
                alt={option.name} 
                style={{ maxWidth: '100%', maxHeight: '100px', objectFit: 'contain' }}
              />
            ) : (
              <div className="image-placeholder">
                {loadedImages[option.name] === false ? 'Error loading image' : 'Loading...'}
              </div>
            )}
            <p>{option.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const CombinedPreview = ({ traits, traitOptions }) => {
  const orderedCategories = ['Bkg', 'Race-eyes', 'Niqab', 'Accss', 'Ovr'];

  return (
    <div className="combined-preview">
      <h3>Final Preview</h3>
      <div className="preview-image">
        {orderedCategories.map(category => {
          const option = traitOptions[category]?.find(o => o.name === traits[category]);
          return option ? (
            <img 
              key={category}
              src={option.image}
              alt={category}
              style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
            />
          ) : null;
        })}
        <img 
          src={watermarkImage}
          alt="Watermark"
          style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
        />
      </div>
    </div>
  );
};

export default RobotGenerator;